import * as React from "react";

import { faCar } from "@fortawesome/sharp-regular-svg-icons/faCar";
import { faCoffee } from "@fortawesome/sharp-regular-svg-icons/faCoffee";
import { faCoins } from "@fortawesome/sharp-regular-svg-icons/faCoins";
import { faHotel } from "@fortawesome/sharp-regular-svg-icons/faHotel";
import { faLamp } from "@fortawesome/sharp-regular-svg-icons/faLamp";
import { faLightbulb } from "@fortawesome/sharp-regular-svg-icons/faLightbulb";
import { faPear } from "@fortawesome/sharp-regular-svg-icons/faPear";
import { faPlane } from "@fortawesome/sharp-regular-svg-icons/faPlane";
import { faTicket } from "@fortawesome/sharp-regular-svg-icons/faTicket";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { getFakeDateString } from "~utils/date_utils/getFakeDateString";
import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimationPaymentsListItemStatus } from "~animations/in_body_illustrations/AnimationPaymentsList/AnimationPaymentsListItemStatus";

import * as styles from "./styles.css";

import type { StatusOption } from "~animations/in_body_illustrations/AnimationPaymentsList/statuses";
import type { BoxProps } from "~components/Box";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTransactionListItemProps
  extends StoryblokBlok,
    BoxProps {
  iconBackgroundColor?: "dark" | "light";
  isDateVisible?: boolean;
  isStatusVisible?: boolean;
  title?: string;
  value: number;
  icon: TransactionListItemEnum | undefined;
  status?: StatusOption;
  swatchColor?: string;
  index: number;
  lang?: EnabledLanguageIsoCode;
}

const TRANSACTION_LIST_ICON_MAP = {
  faCar,
  faCoffee,
  faCoins,
  faHotel,
  faLamp,
  faLightbulb,
  faPear,
  faPlane,
  faTicket,
} as const;

export type TransactionListItemEnum = keyof typeof TRANSACTION_LIST_ICON_MAP;

export function AnimLayerTransactionListItem({
  iconBackgroundColor,
  isDateVisible,
  isStatusVisible,
  title,
  lang = "en-gb",
  value,
  icon,
  status,
  swatchColor,
  index,
  ...rest
}: AnimLayerTransactionListItemProps) {
  const dynamicIcon = icon ? TRANSACTION_LIST_ICON_MAP[icon] : undefined;

  return (
    <StoryblokEditable key={rest._uid} {...rest}>
      <Box
        alignItems="center"
        className={styles.list}
        display="flex"
        gap="spacing1"
        key={rest._uid}
        marginY="spacing1"
        paddingY="spacing1"
      >
        {dynamicIcon && (
          <IconFontAwesome
            width="spacing2"
            height="spacing2"
            borderRadius="50%"
            padding="spacing1"
            className={
              iconBackgroundColor === "dark"
                ? variantBackgroundColor.background_charcoal
                : variantBackgroundColor.background_sand
            }
            icon={dynamicIcon}
          />
        )}
        {!icon && <Box className={styles.swatch} __color={swatchColor} />}
        <Box>
          <Box color="text_highContrast">{title}</Box>
          {isDateVisible && (
            <Box color="text_lowContrast">{getFakeDateString(index, lang)}</Box>
          )}
        </Box>

        <Box marginLeft="auto" fontWeight="medium" color="text_highContrast">
          -{formatCurrencyString({ amount: value, lang })}
        </Box>
        {isStatusVisible && (
          <Box className={styles.status}>
            {status && <AnimationPaymentsListItemStatus status={status} />}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
