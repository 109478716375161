import React, { useContext } from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerUiAccountInfoCardProps extends BoxProps {
  title: string;
  amount: number;
  button1: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  button2: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
}

export function AnimLayerUiAccountInfoCard({
  amount,
  button1: button1BlokArray,
  button2: button2BlokArray,
  className: userClassName,
  title,
  ...rest
}: AnimLayerUiAccountInfoCardProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const [button1] = button1BlokArray || [];
  const [button2] = button2BlokArray || [];

  return (
    <StoryblokEditable {...rest}>
      <Box
        borderRadius="sm"
        className={clsx(userClassName, variantBackgroundColor.background_sand)}
        display="flex"
        flexDirection="column"
        gap="spacing2"
        paddingX="spacing1.5"
        paddingY="spacing3"
        {...rest}
      >
        <Box
          textAppearance="body_sm"
          color="text_lowContrast"
          data-motion={ANIMATED.secondary}
        >
          {title}
        </Box>
        <Box
          textAppearance="body_lg"
          fontWeight="semibold"
          data-motion={ANIMATED.secondary}
        >
          {formatCurrencyString({
            amount,
            lang,
          })}
        </Box>
        {(button1 || button2) && (
          <Box
            display="grid"
            __gridTemplateColumns="repeat(2,1fr)"
            gap="spacing1"
            data-motion={ANIMATED.secondary}
          >
            {button1 && (
              <AnimLayerFakeButton variant="secondary" {...button1} size="sm" />
            )}
            {button2 && (
              <AnimLayerFakeButton variant="primary" {...button2} size="sm" />
            )}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
