import React from "react";

import { faBolt } from "@fortawesome/sharp-regular-svg-icons/faBolt";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimLayerUiPayoutMethodProps extends StoryblokBlok, BoxProps {
  bankName?: string;
  ctaText?: string;
  description?: string;
  image?: StoryblokFieldMedia;
  textAccount?: string;
  title?: string;
}

export function AnimLayerUiPayoutMethod({
  bankName,
  ctaText,
  description,
  image,
  textAccount,
  title,
  ...rest
}: AnimLayerUiPayoutMethodProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        borderRadius="sm"
        padding="spacing2"
        display="flex"
        flexDirection="column"
        gap="spacing2"
        className={variantBackgroundColor.background_sand}
        {...rest}
      >
        <Box borderBottom="default" paddingBottom="spacing2">
          <Box
            display="flex"
            gap="spacing1"
            alignItems="center"
            marginBottom="spacing1"
            data-motion={ANIMATED.secondary}
          >
            <IconFontAwesome size="xs" icon={faBolt} />
            {title && (
              <Box textAppearance="body_sm" fontWeight="semibold">
                {title}
              </Box>
            )}
            {ctaText && (
              <Box
                marginLeft="auto"
                textAppearance="body_sm"
                __color={colorTokens_latest.Secondary.Water_azure[130].value}
              >
                {ctaText}
              </Box>
            )}
          </Box>
          {description && (
            <Box
              data-motion={ANIMATED.secondary}
              marginLeft="auto"
              textAppearance="body_sm"
            >
              {description}
            </Box>
          )}
        </Box>

        <Box
          textAppearance="body_sm"
          display="flex"
          gap="spacing1"
          alignItems="center"
          data-motion={ANIMATED.secondary}
        >
          {image && (
            <Image maxWidth="spacing1" maxHeight="spacing2" image={image} />
          )}
          {bankName && textAccount && (
            <Box>
              {bankName} {textAccount}
              {" **** 5678"}
            </Box>
          )}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
