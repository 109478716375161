import React from "react";

import { faChevronLeft } from "@fortawesome/sharp-regular-svg-icons/faChevronLeft";
import { faUserCircle } from "@fortawesome/sharp-regular-svg-icons/faUserCircle";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import type { BoxProps } from "~components/Box";

const ICON_MAP = {
  faUserCircle,
  faChevronLeft,
} as const;

type IconMapKey = keyof typeof ICON_MAP;

interface AnimLayerUiTopNavProps extends BoxProps {
  title: string;
  iconLeft?: IconMapKey;
  iconRight?: IconMapKey;
}

export function AnimLayerUiTopNav({
  iconLeft,
  iconRight,
  title,
  ...rest
}: AnimLayerUiTopNavProps) {
  const dynamicIconRight = iconRight && ICON_MAP[iconRight];
  const dynamicIconLeft = iconLeft && ICON_MAP[iconLeft];

  return (
    <Box
      display="grid"
      __gridTemplateColumns="1fr auto 1fr"
      gap="spacing2"
      alignItems="center"
      padding="spacing3"
      {...rest}
    >
      {dynamicIconLeft ? <IconFontAwesome icon={dynamicIconLeft} /> : <div />}

      <Box textAppearance="body_lg" fontWeight="semibold" textAlign="center">
        {title}
      </Box>

      {dynamicIconRight ? (
        <IconFontAwesome marginLeft="auto" icon={dynamicIconRight} />
      ) : (
        <div />
      )}
    </Box>
  );
}
