import React from "react";

import { AnimLayerCalendar } from "~animations/__layers__/mock_ui_components/AnimLayerCalendar";

import { AnimLayerTransparentPhoneBg } from "../AnimLayerTransparentPhoneBg";

import type { AnimLayerCalendarProps } from "~animations/__layers__/mock_ui_components/AnimLayerCalendar";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPhoneBgCalendarProps extends BoxProps {
  animLayerCalendar: Array<StoryblokBlok & AnimLayerCalendarProps>;
}

export function AnimLayerPhoneBgCalendar({
  animLayerCalendar: animLayerCalendarBlokArray,
  ...rest
}: AnimLayerPhoneBgCalendarProps) {
  const [animLayerCalendar] = animLayerCalendarBlokArray || [];

  return (
    <AnimLayerTransparentPhoneBg {...rest}>
      {animLayerCalendar && (
        <AnimLayerCalendar marginX="spacing2" {...animLayerCalendar} />
      )}
    </AnimLayerTransparentPhoneBg>
  );
}
